import { FetchConfig, Product } from '../redux/apiTypes';

export interface PostProductsParams {
  storeId: string;
  eansList: string[];
  deliveryType?: string;
  fetchConfig: FetchConfig;
}

const requestPostProducts = async (
  params: PostProductsParams,
): Promise<Product[]> => {
  const { storeId, eansList, deliveryType, fetchConfig } = params;
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const url = `${apiRoot}/stores/${storeId}/products`;

  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Accept-Language': language,
      'x-chain': xChain,
      'x-version': xVersion,
      'x-delivery-type': deliveryType,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ eans: eansList }),
  });

  if (!response.ok) {
    throw new Error(`An error occurred while fetching the data: ${url}`);
  }

  return await response.json();
};

export default requestPostProducts;
